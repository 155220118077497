import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import './main.scss'
// Import custom components
import MessengerCustomerChat from 'react-messenger-customer-chat';

import HeaderFour from "../../common/headers/header-four"
import SpecialProducts from "./special-products"
import FooterOne from "../../common/footers/footer-one";
import ThemeSettings from "../../common/theme-settings"
import img1 from '../../../assets/images/pic1.jpg'
import img8 from '../../../assets/images/pic8.jpg'
import img5 from '../../../assets/images/pic5.jpg'
import img6 from '../../../assets/images/pic6.jpg'
import ReactGA from 'react-ga';

// import '../../../assets/images/'

class Electronic extends Component {

    componentDidMount() {
        this.setGA();
        console.log('object 12344 homepage');
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color7.css`);
    }
    setGA = () => {
        console.log('react ga')
        ReactGA.initialize('UA-219890415-1');
        ReactGA.pageview('Init page view');
      };
 
    render() {

        return (
            <div className="container-fluid layout-8">
               <Helmet>
                    <title>BestBuy</title>
                    <meta property="og:title" content="test title 1" /> 
                    <meta property="og:description" content="test content" />
                    <meta property="og:image" content="https://miro.medium.com/max/1080/1*VnPIv9EBsV00LCbIbPFTRQ.jpeg" />
                </Helmet>
                <HeaderFour logoName={'logo/3.png'} history={this.props.history} />

                {/*  */}

                <div className="">
                    {/*About Section*/}
                    {/* <section className="banner-goggles ratio2_3">
                        <div className="container-fluid">
                            <div className="row partition3">
                                <div className="col-md-4">
                                    <a href="#">
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/electronics/sub1.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                    <h4>10% off</h4>
                                                    <h2>speaker</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4">
                                    <a href="#">
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/electronics/sub2.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                    <h4>10% off</h4>
                                                    <h2>earplug</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4">
                                    <a href="#">
                                        <div className="collection-banner">
                                            <div className="img-part">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/electronics/sub3.jpg`}
                                                     className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <div className="contain-banner banner-3">
                                                <div>
                                                    <h4>50% off</h4>
                                                    <h2>best deal</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/*About Section End*/}

                    {/*Product slider*/}
                    <SpecialProducts type={'electronics'} />
                    {/*Product slider End*/}
                </div>
                <div className="footer-white">
                    {/* <h1>hello world</h1> */}
                    <MessengerCustomerChat
                        pageId="101592648099324"
                        appId="369574198086778"
                    />
                    <FooterOne logoName={'logo/3.png'} />
                </div>

                {/* <ThemeSettings /> */}
            </div>
        )
    }
}


export default Electronic;