import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
import HeaderFour from './common/headers/header-four';

import FooterOne from "./common/footers/footer-one";
import MessengerCustomerChat from 'react-messenger-customer-chat';

// ThemeSettings
import ThemeSettings from "./common/theme-settings"

import './landing.scss'
class App extends Component {
    constructor(props){
        super(props);
    }
   
    render() {
        return (
            <div className='header__main'>
                
                <HeaderFour logoName={'logo.png'} />
                {this.props.children}
                <MessengerCustomerChat
                        pageId="101592648099324"
                        appId="369574198086778"
                    />
                    <MessengerCustomerChat
                        pageId="101592648099324"
                        appId="369574198086778"
                    />
                <FooterOne logoName={'logo.png'}/>

                {/* <ThemeSettings /> */}
                
            </div>
        );
    }
}

export default withTranslate(App);
